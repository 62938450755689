.content {
  width: 100%;
  background: #FFFFFF;
  border-radius: 24px;
  display: flex;
  min-height: 300px;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;

  &__disabled{
    pointer-events: none;
    opacity: 0.8;
  }
  .content__container{
    padding: 2% 2% 2% 0;

    .head__title {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      font-feature-settings: 'ss02' on;
      color: #2D3436;
    }
    .content__text{
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #636E72;
    }
    .content__header{
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #2D3436;
      padding-bottom: 1%;
    }
    .content__submit__button{
      padding: 1%;
      color: white;
      text-transform: initial;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      background: #0984E3;
      border-radius: 12px;
    }
  }
}