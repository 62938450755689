.block_ips {
  border: 1px solid #EEEFEF;
  border-radius: 16px;
  padding: 16px;
  margin: 28px 0;
  display: flex !important;
  align-items: center;
}

.ips_text_title {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #2D3436;
}

.ips_text {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2D3436;
  overflow-wrap: anywhere;
}

.copy_ips {
  color: #0984E3;
  display: flex;
  align-items: center;
  text-transform: none !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}