.table__container{
  margin-top: 2%;
  min-height: 460px;
  background: #FFFFFF;
  border-radius: 24px;
  .table__label__container{
    padding: 2% 2% 16px;
    justify-content: space-between;
    align-items: center;
  }
  .table__label{
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #2D3436;
  }
}
.infinite__scroll{
  overflow: initial!important;
  text-align: center;
  padding-bottom: 2%;
}
.filters__filter__icon__container{
  margin-left: 16px !important;
  width: 48px;
  height: 48px;
  background: #F2F5F6;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  color: #636E72;
  align-items: center;
  cursor: pointer;
  :hover{
    color: black;
  }
}
.filters__text__container{
  display: flex;
  align-items: center;
}
.filters__icon___container{
  display: flex;
  align-items: center;
  color: #636E72;
  cursor: pointer;
}
.filters__text{
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #636E72;
  padding-right: 12px;
}
