.header__container{
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.08em;
}
.header__logo{
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  color: #2D3436;
  text-transform: uppercase;
}