.balance__loader__container{
  margin-left: -5%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.balance__item {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.container__title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon {
  width: 24px;
  height: 24px;
}

.platform_name {
  padding-left: 12px;
  color: #2D3436;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.balance__currency__container {
  display: flex;
  flex-direction: row;
  padding-top: 16px;
}

.balance__total__container {
  display: flex;
  flex-direction: column;
  flex: 1 0 50%;
}

.balance__available__container {
  display: flex;
  flex-direction: column;
  flex: 1 0 50%;
}

.balance__name {
  color: #636E72;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.balance__amount {
  color: #2D3436;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
}

.available__balance__container {
  display: flex;
  flex-direction: column;
}

.blockError {
  background: rgba(214, 48, 49, 0.04);
  border: 1px solid rgba(214, 48, 49, 0.04);
  border-radius: 12px;
  padding: 16px;
  margin: 10px;
}

.blockError:hover {
  background: rgba(9, 5, 5, 0.04);
  cursor: pointer;
}

.error_small_text {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #636E72;
}
