.modal__container{
  align-items: center;
  justify-content: center;
  height: 100%;
}
.modal__inner__container {
  padding-top: 20px;
  box-shadow: 0 16px 48px rgba(45, 52, 54, 0.16);
  border-radius: 24px;
  width: 100%;
  min-height: 30%;
  background: white;
}
.header_button {
  display: flex;
  align-items: center;
  width: 100%;
}

.arrow__back {
  padding-left: 32px;
  padding-right: 16px;
}

.arrow__close {
  margin: 0 0 0 auto !important;
  padding-right: 16px;
}

.close__button{
  font-size: 26px;
  cursor: pointer;
}

.img_title {
  width: 32px;
  height: 32px;
}

.modal__base{
  background-color: rgba(0,0,0,0.1);
  overflow: scroll;
}
.header__container{
  display: flex;
  align-items: center;
  .header__label{
    padding-left: 16px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #2D3436 !important;
  }
}

.content__container{
  padding: 0 0 32px 32px;
  margin-top: 20px;
  margin-right: 32px;
}