.login__form__name{
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}
.login__form__help__text{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #636E72;
  margin-top: 3% !important;
}
.login__form__password__reset__text{
  display: flex;
  width: 100%;
  height: 40px;
  padding: 2%;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #2D3436;
  margin-top: 5%;
}
.login__form__password__reset__icon{
  color: #00B894;
  padding-right: 3%;
}
.login__form__loading{
  pointer-events: none;
  opacity: 0.5;
}
.login__form__container{
  border-radius: 24px;
  background: white;
  padding: 6%;
  width: 70%;
}