.login__page__container {
  height: 100%;
}
.login__page__sign_in__form__container{
  align-items: center;
  justify-content: center;
  height: 100%;
}
.login__page{
  height: 100vh;
  background: #F2F5F6;
  padding: 2.5%;
}