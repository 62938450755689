.button__run{
  color: #0984E3 !important;
}
.button{
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-transform: capitalize !important;
  border-radius: 24px !important;
}
.button__run:disabled{
  color: rgba(0, 0, 0, 0.26) !important;
}
.button__content{
  display: flex;
  align-items: center;
}
.button__stop{
  color: #D63031 !important;
}
.button__icon{
  font-size: 24px;
}