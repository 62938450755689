.forgot__form__name{
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}
.forgot__help__text{
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #636E72;
  padding-top: 3%;
}
.success{
  color: #00B894;
}
.forgot__form__loading{
  pointer-events: none;
  opacity: 0.5;
}
.forgot__form__go__back__link{
  text-decoration: none;
}
.forgot__form__go__back__button{
  margin-top: 3% !important;
  text-align: center !important;
  justify-content: center !important;
  width: 100%;
  color: #0984E3 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  text-transform: initial!important;
}
.forgot__form__container{
  border-radius: 24px;
  background: white;
  padding: 6%;
  width: 70%;
}