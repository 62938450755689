.trading__pair__label{
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #2D3436;
}
.stop__loss__label{
  margin-top: 3%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
}
.stop__loss__container{
  margin-bottom: 38px;
}
.stop__loss__strategy__label{
  padding-left: 2%;
  color: #2D3436;
}
.disabled{
  opacity: 0.3;
}
.available__funds__label{
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: end;
}
.form__is__loading{
  pointer-events: none;
  opacity: 0.5;
}
.error__message{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
}