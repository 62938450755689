.filters__select{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  background: #F2F5F6;
  border-radius: 12px;
  border-left: none;
  border-bottom: none;
  border-top: none;
  border-right: 16px solid transparent;
  height: 48px;
  padding-left: 12px;
  padding-right: 16px;
  outline: none;
  scroll-behavior: smooth;
  cursor: pointer;
}
.filters__container{
  display: flex;
  justify-content: center;
  align-items: center;
}