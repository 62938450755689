.tooltip{
  position: relative;
  display: inline-block;
}
.disabled{
  pointer-events: none;
  color: lightgrey !important;
}
.tooltip .tooltip__text{
  width: 120px;
  background-color: white;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #636E72 !important;
  text-align: center;
  border-radius: 24px;
  padding: 5px 0;
  margin-left: 10px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}
.tooltip__inactive{
  visibility: hidden;
}
.tooltip__active{
  visibility: visible;
}
.tooltip__option{
  margin-bottom: 5px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}