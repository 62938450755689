.button{
  background: #FFFFFF;
  border-radius: 12px;
  border: none;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  cursor: pointer;
}
.icon{
  font-size: 20px !important;
  color: #2D3436 !important;
}