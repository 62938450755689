.container{
  margin-bottom: 8px;
}
.text{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #2D3436;
}

.radio {
  z-index: 0;
}
