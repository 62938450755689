.content{
  text-align: center;
  .content__text{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #636E72;
    margin-bottom: 15px;
  }
  .button__container{
    display: flex;
    justify-content: center;
    vertical-align: bottom;
  }
}