  .table{
    border-collapse: separate !important;
    border-spacing: 0 4px !important;
  }
  .table__heading{
    th{
      padding-left: 2%;
      border-bottom: 1px solid #F2F5F6;
      color: #636E72;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
    }
    tr{
      :last-child{
        text-align: end;
        padding-right: 2%;
      }
    }
  }
  .table__header__cell{
    cursor: initial;
  }
  .table__header__sortable{
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }
  .table__header__sortable:hover{
    color: black;
  }
  .table__body{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    tr{
      :last-child{
        padding-right: 2%;
        text-align: end;
      }
    }
    td{
      color: #2D3436;
      padding-left: 2%;
      border-bottom: 1px solid #F2F5F6;
    }
    .type__sell{
      color: #D63031;
    }
    .type__buy{
      color: #00B894;
    }
    :last-child td{
      padding-left: 2%;
      border-bottom: none;
    }
  }

.blurred__type{
  width: 26px;
  height: 10px;
  border-radius: 100px;
}
.blurred__sell{
  background: rgba(214, 48, 49, 0.12);
}
.blurred__buy{
  background: rgba(0, 184, 148, 0.12);
}
.blurred__configuration{
  width: 90px;
  height: 10px;
  background: rgba(45, 52, 54, 0.06);
  border-radius: 100px;
}
.blurred__configuration__long{
  width: 140px;
  height: 10px;
  background: rgba(45, 52, 54, 0.06);
  border-radius: 100px;
}
.loader__container{
  display: flex;
  justify-content: center;
}
.no__data__container{
  display: flex;
  justify-content: center;
  align-items: center;
}
.no__data__label{
  position: absolute;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #2D3436;
}
.blurred__asset{
  background: rgba(45, 52, 54, 0.06);
  border-radius: 100px;
  width: 27px;
  height: 10px;
}
.blurred__amount{
  background: rgba(45, 52, 54, 0.06);
  border-radius: 100px;
  width: 42px;
  height: 10px;
}
.blurred__status{
  width: 38px;
  background: rgba(45, 52, 54, 0.06);
  border-radius: 100px;
  height: 10px;
}
.blurred__price{
  background: rgba(45, 52, 54, 0.06);
  border-radius: 100px;
  width: 58px;
  height: 10px;
}
.blurred__datetime{
  background: rgba(45, 52, 54, 0.06);
  border-radius: 100px;
  width: 100%;
  height: 10px;
}
.empty__table__body{
  td{
    width: 50px;
    background: rgba(45, 52, 54, 0.06);
    border-radius: 100px;
  }
}
.infinite__scroll{
  overflow: initial!important;
  text-align: center;
  padding-bottom: 2%;
}
.filters__text__container{
  display: flex;
  align-items: center;
}
.filters__icon___container{
  display: flex;
  align-items: center;
  color: #636E72;
  cursor: pointer;
}
.filters__text{
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #636E72;
}
