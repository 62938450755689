.label{
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  color: #2D3436 !important;
}
.activation__button{
  text-transform: inherit !important;
  background: #FFFFFF !important;
  border-radius: 12px 0 0 12px !important;
  border-right: 1px solid rgba(45, 52, 54, 0.08) !important;
  color: #2D3436 !important;
}
.edit__button{
  background: #FFFFFF !important;
  border-radius: 0 12px 12px 0 !important;
  color: #2D3436 !important;
  text-transform: inherit !important;
  .edit__icon{
    font-size: 24px;
    padding-left: 5px;
    padding-right: 5px;
  }
}
.delete__button{
  text-transform: inherit !important;
  margin-left: 15px !important;
  background: #FFFFFF !important;
  border-radius: 12px !important;
  color: #D63031 !important;
  .delete__icon{
    font-size: 24px;
    padding-left: 5px;
    padding-right: 5px;
  }
}
.button__disabled{
    color: rgba(45, 52, 54, 0.48) !important;
}
.table{
  margin-top: 10px;
}
.balance__profit__positive{
  margin-left: 8px;
  color: #00B894;
}
.balance__profit__negative{
  margin-left: 8px;
  color: #D63031;
}
.loader__container{
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.status{
  display: flex;
  align-items: center;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 22px !important;
}
.status__icon{
  font-size: 10px !important;
  padding-right: 5px;
}
.active{
  color: #00B894 !important;
}
.stopped{
  color: #636E72 !important;
}
.table{
    padding: 0 8% 2%;
}
.table__field__value{
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #2D3436 !important;
  padding-right: 0 !important;
  border-bottom: 1px solid #F2F5F6 !important;
  display: flex !important;
  align-items: center;
}
.table__field__image {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.table__field__name{
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #636E72 !important;
  padding-left: 0 !important;
  border-bottom: 1px solid #F2F5F6 !important;
}
.content__container{
  background: #FFFFFF;
  border-radius: 24px;
  padding: 24px;
  margin-bottom: 20px;
  min-width: 215px;
}
.balance__amount__container{
  padding-top: 30px;
  display: flex;
  cursor: pointer;
  .balance__profit__container{
    display: flex;
    align-items: flex-end;
    margin-left: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    .balance__profit__positive{
      margin-left: 8px;
      color: #00B894;
    }
    .balance__profit__negative{
      margin-left: 8px;
      color: #D63031;
    }
  }
  .balance__amount{
    font-weight: 700;
    font-size: 28px;
    color: #2D3436;
  }
  .balance__currency{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #636E72;
    padding-left: 2%;
    display: flex;
    align-items: flex-end;
  }
}
.deposit__container{
  padding-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.deposit__button{
  text-transform: capitalize !important;
  width: 45%;
  height: 40px !important;
  border: 1px solid rgba(45, 52, 54, 0.08) !important;
  border-radius: 12px !important;
}