.input__container{
  margin-bottom: 28px;
}
.input__container__no__label{
  margin-bottom: 28px;
}
.input__label {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #2D3436;
  margin-bottom: 8px !important;
}
.input{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  background: #F2F5F6;
  border-radius: 12px;
  height: 48px;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.input__error{
  font-family: 'Poppins', sans-serif;
  justify-content: center;
  justify-items: center;
  align-items: center;
  height: 48px;
  font-style: normal;
  background: #F2F5F6;
  border-radius: 12px;
  border-bottom: 1px solid #d32f2f !important;
}
.input__error_message{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
}