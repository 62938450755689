.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.container__button {
  width: 100%;
  display: flex;
}

.block__button {
  display: flex;
  justify-content: center;
}

.button__back {
  background: #FFFFFF;
  border: 1px solid #EEEFEF !important;
  border-radius: 12px;
  color: #2D3436 !important;
  text-transform: none !important;
}

.button__back:hover {
  box-shadow: 0 2px 2px -2px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.textHint {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #636E72;
}