.wrapper {

    .value {
        .suffix {
            color: #636E72;
            margin-left: 8px;
            font-weight: 400;
        }
    }

    &.small {
        .label {
            line-height: 20px;
            color: #636E72;
            font-size: 12px;
            font-weight: 400;
        }

        .value {
            line-height: 26px;
            font-size: 18px;
            font-weight: 700;
        }
    }


    &.medium {
        .label {
            line-height: 22px;
            color: #2D3436;
            font-size: 14px;
            font-weight: 600;
        }

        .value {
            line-height: 36px;
            font-size: 28px;
            font-weight: 700;

            .suffix {
                font-size: 14px;

            }
        }
    }
}