.configurations__container{
  min-height: 700px;
}
.empty__configurations__container{
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  padding-right: 2.5%;
}
.empty__card{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  .empty__card__heading{
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #2D3436;
  }
  .empty__card__subtitle{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #636E72;

  }
  .empty__card__button{
    min-width: 200px;
    text-transform: initial;
    margin-top: 5%;
    padding: 3%;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
    background: #0984E3;
    border-radius: 12px;
  }
}
.loader__container{
  align-items: center;
  justify-content: center;
  min-height: 700px;
  width: 100%;
}
.create__configuration__button{
  padding: 10px 20px;
  border: none;
  text-transform: initial;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #FFFFFF;
  background: #0984E3;
  border-radius: 12px;
  min-height: 40px;
}
.create__configuration__button:hover{
  opacity: 0.7;
  transition: 0.3s;
}