.card__container{
  border-radius: 24px;
  background: white;
  min-width: 370px;
  margin-bottom: 1% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.active{
  color: #00B894 !important;
}
.status__icon{
  font-size: 10px !important;
  padding-right: 5px;
}
.card__padding{
  padding: 8% 8% 2%;
}
.card__header{
  padding-left: 8%;
  padding-top: 8%;
  padding-right: 8%;
  justify-content: space-between;
  cursor: pointer;
}
.card__table__padding{
  padding: 0 8% 2%;
  cursor: pointer
}
.table__field__value{
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #2D3436 !important;
  padding-right: 0 !important;
  border-bottom: 1px solid #F2F5F6 !important;
  display: flex !important;
  align-items: center;
}
.table__field__image {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.table__row{
  height: 55px !important;
}
.stop__message__container{
  text-align: center;
  height: 20px;
  max-width: 310px;
  padding-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #D63031;
  font-size: 10px !important;
  line-height: 16px !important;
}
.table__field__name{
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #636E72 !important;
  padding-left: 0 !important;
  border-bottom: 1px solid #F2F5F6 !important;
}
.balance__amount__container{
  margin-bottom: 2vh;
  display: flex;
  cursor: pointer;
  .balance__profit__container{
    display: flex;
    align-items: flex-end;
    margin-left: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    .balance__profit__positive{
      margin-left: 8px;
      color: #00B894;
    }
    .balance__profit__negative{
      margin-left: 8px;
      color: #D63031;
    }
  }
  .balance__amount{
    font-weight: 700;
    font-size: 28px;
    color: #2D3436;
  }
  .balance__currency{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #636E72;
    padding-left: 2%;
    display: flex;
    align-items: flex-end;
  }
}
.configuration__name{
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #2D3436;
}
.detail__icon__container{
  display: flex;
  align-items: center;
  .detail__icon{
    color: #636E72;
    cursor: pointer;
  }
  :hover{
    color: black;
  }
}
.configuration__trading__pair{
  margin-top: 2%;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #636E72;
  text-transform: capitalize;
}
.deposit__container{
  justify-content: space-between;
  .deposit__button{
    text-transform: capitalize;
    display: flex;
    border: 1px solid rgba(45, 52, 54, 0.08);
    min-width: 132px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 20px;
    background: #FFFFFF;
    border-radius: 12px;
    color: #0984E3;
  }
  .deposit__button:hover{
  }
}
.hr{
  border-color: #F2F5F6;
  margin-top: 7%;
  padding: 0 !important;
  width: 100%;
  background: #F2F5F6;
}
.button__container{
  padding-bottom: 15px;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}