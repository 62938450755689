.logout__icon{
  font-size: 20px;
  color: #D63031;
}
.success__message{
  transition: 0.4s;
  color: #00B894;
}
.success__message.hidden{
  opacity: 0;
  transition: 0.4s;
}
.content__margin{
  margin-bottom: 2%;
}
.edit__button{
  border-radius: 12px !important;
  background-color: #0984E3 !important;
  text-transform: initial !important;
  height: 48px;
}
.content{
  width: 100%;
  background: #FFFFFF;
  border-radius: 24px;
  min-height: 300px;
  &__disabled{
    pointer-events: none;
    opacity: 0.8;
  }
  .content__container{
    padding: 2%;
    .content__text{
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #636E72;
    }
    .content__header{
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #2D3436;
      padding-bottom: 1%;
    }
    .content__submit__button{
      padding: 1%;
      color: white;
      text-transform: initial;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      background: #0984E3;
      border-radius: 12px;
    }
  }
}