.page__label{
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #2D3436;
}
.header__container{
  justify-content: space-between;
  padding-bottom: 2%;
}
.create__configuration__button{
  padding: 10px 20px;
  border: none;
  text-transform: initial;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #FFFFFF;
  background: #0984E3;
  border-radius: 12px;
  min-height: 40px;
}
.navigate__button{
  color: #1976d2 !important;
  background:transparent;
  border: none;
  cursor: pointer;
  font-weight: bold;
}
.slide__button__left{
  background: #FFFFFF !important;
  border-radius: 12px 0 0 12px !important;
  border-right: 1px solid rgba(45, 52, 54, 0.08) !important;
  color: #2D3436 !important;
}
.slide__button__right{
  background: #FFFFFF !important;
  border-radius: 0 12px 12px 0 !important;
  color: #2D3436 !important;
}
.slide__button__disabled{
  color: rgba(45, 52, 54, 0.48) !important;
}
.create__configuration__button:hover{
  opacity: 0.7;
  transition: 0.3s;
}
.create__configuration__button:disabled{
  opacity: 0.5;
  cursor: initial;
  pointer-events: none;
}