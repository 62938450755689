.currency__label{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #636E72;
}
.currency__label__container{
  background: #F2F5F6;
  border-radius: 0 12px 12px 0;
  height: 48px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  display: flex;
  padding-right: 16px;
}
.currency__label__container__error{
  border-bottom: 1px solid #d32f2f !important;
}
.input{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  background: #F2F5F6;
  border-radius: 12px;
  height: 48px;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.input__currency{
  border-radius: 12px 0 0 12px;
}
.input__error{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  background: #F2F5F6;
  border-radius: 12px 0 0 12px;
  border-bottom: 1px solid #d32f2f !important;
}
.input__container{
  display: flex;
}