.container{
  color: #76777c;
  text-align: center;
}
.navigate__section{
  //position: fixed !important;
  //top:2%;
  //left:50%;
}
.navigate__button__container{
  display: flex;
  justify-content:center;
}
.navigate__button{
  color: #1976d2 !important;
}