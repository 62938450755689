.main {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal !important;
  min-height: 100vh;
}
body {
  margin: 0;
  padding: 0;
}
.disable__text__selection{
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}