.header{
  justify-content: space-between;
  padding-bottom: 2%;
}
.settings__label{
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #2D3436;
}
.logout__button{
  display: flex;
  justify-content: space-between !important;
  border-radius: 12px !important;
  color: #D63031 !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-transform: initial;
  width: 98px;
  height: 40px;
}

.home__page{
  height: 100vh;
}
.home__page__content{
  background: #E5E5E5;
  padding: 2%;
}