.content {
  width: 93%;
  margin-bottom: 20px;
  min-height: 72px;
  border: 1px solid #EEEFEF;
  border-radius: 16px;
  padding: 18px 0 0px 24px;
  display: flex;
  align-items: center;
}

.container__title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon {
  width: 24px;
  height: 24px;
}

.platform_name {
  padding-left: 12px;
  color: #2D3436;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.balance_name {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #636E72;
}

.balance_amount {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #2D3436;
}

.blockTradingPlatform {
  padding: 16px 16px 16px 20px;
}

.blockBalance {
  padding: 16px;
}

.blockErrorMessage {
  border-radius: 0 0 16px 16px;
  width: 103% !important;
  margin-left: -8px;
  background: rgba(214, 48, 49, 0.04);
  padding: 16px 16px 13px 23px;
}

.errorDescription {
  font-family: "Poppins", sans-serif;
  font-style: normal!important;
  font-weight: 500 !important;
  font-size: 12px!important;
  line-height: 20px!important;
}