.content__text{
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #636E72;
  text-align: center;
  margin-bottom: 15px !important;
}
.button{
  background: #0984E3;
  border-radius: 12px;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  border: none;
  height: 50px;
  width: 100%;
  cursor: pointer;
  :hover{
    background: #0984E9;
    transition: 0.5s;
  }
}
.delete__button{
  background: #D63031;
  border-radius: 12px;
  border: none;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  height: 50px;
  width: 100%;
  cursor: pointer;
  &:hover{
    background: #D11000;
    transition: 0.5s;
  }
}
