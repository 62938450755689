.trading__pair__label{
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #2D3436;
}
.available__funds__label{
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: end;
}
.loader__container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center
}
.content__text{
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #636E72;
  margin-bottom: 15px;
}