.input{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  background: #F2F5F6;
  border-radius: 12px;
  height: 48px;
  border: none;
  outline: none;
  scroll-behavior: smooth;
  border-right: 16px solid transparent;

  :global(.react-select__placeholder) {
    color: #AAAAAA !important;
  }
}
.input__error{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  background: #F2F5F6;
  border-radius: 12px;
  height: 48px;
  padding-left: 2%;
  border: none;
  outline: none;
  scroll-behavior: smooth;
  border-bottom: 1px solid #d32f2f !important;
}
.input__container{
  margin-bottom: 28px;
  margin-top: 28px;
}
.input__container__no__label{
  margin-bottom: 28px;
}
.input__label {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #2D3436;
  margin-bottom: 8px !important;
}
.input__error_message{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
}