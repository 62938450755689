.logo{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  color: #2D3436;
  text-transform: uppercase;
}
.balance__container{
  margin-top: 2vh;
  margin-bottom: 2vh;
}
.balance__amount__container{
  .balance__loader__container{
    margin-left: -5%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.tooltip__container{
  width: 100%;
  display: flex;
  gap: 7%;
  .tooltip__button{
    height: 40px;
    width: 42%;
    color: #0984E3;
    border-radius: 12px;
    text-transform: initial;
    border: 1px solid rgba(45, 52, 54, 0.08);
  }
}
.navbar{
  margin-left: 5%;
  margin-top: 10%;
}
