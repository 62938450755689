.button{
  border-radius: 12px !important;
  height: 48px;
  background-color: #0984E3 !important;
}
.button__text{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-transform: initial;
}