.password__visibility__button{
  cursor: pointer;
}
.password__visibility__container{
  background: #F2F5F6;
  border-radius: 0 12px 12px 0;
  height: 48px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  display: flex;
  padding-right: 2%;
}
.forgot__password__block{
  text-align: end;
}
.forgot__password__text{
  font-family: "Poppins", sans-serif;
  font-style: normal;
  text-decoration: none;
  color: #0984E3;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}
.input{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  background: #F2F5F6;
  border-radius: 12px 0 0 12px;
  height: 48px;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.input__error{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  background: #F2F5F6;
  border-radius: 12px 0 0 12px;
  border-bottom: 1px solid #d32f2f !important;
}
.input__container{
  display: flex;
}