.modal__container{
  align-items: center;
  justify-content: center;
  height: 100%;
}
.modal__inner__container {
  padding-top: 5%;
  box-shadow: 0 16px 48px rgba(45, 52, 54, 0.16);
  border-radius: 24px;
  width: 100%;
  min-height: 20%;
  background: white;
}
.modal__base{
  background-color: rgba(0,0,0,0.1);
  overflow: scroll;
}
.header__container{
  text-align: center;
  .header__label{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #2D3436 !important;
  }
}
.close__button{
  font-size: 20px;
  padding-top: 3%;
  cursor: pointer;
}
.content__container{
  padding: 0 0 5% 5%;
  margin-top: 7%;
  margin-right: 7%;
}
.button__container{
  display: flex;
  justify-content: center;
}
.content__subtitle{
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #636E72;
  text-align: center;
  margin-bottom: 5%;
}
.button{
  border-radius: 12px !important;
  height: 48px;
  background-color: #0984E3 !important;
  width: 50%;
}
.button__text{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-transform: initial;
  color: #FFFFFF;
}