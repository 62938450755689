.nav__container{
  margin-top: 2%;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #636E72;
  display: flex;
  align-items: center;
  height: 56px;
  padding-left: 5%;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.nav__container:hover{
  color: #0984E3;
}
.nav__container__active{
  color: #0984E3;
  background: rgba(9, 132, 227, 0.16);
  border-left: 4px solid #0984E3;
}
.icon{
  font-size: 30px;
}